<template>
  <v-dialog v-model="dialog" scrollable max-width="900" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Article
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="article-form">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <tiptap />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="article-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      article: {},
      fields: {
        title: null,
        slug: null,
      },
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.title;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    open(article = null) {
      if (article !== null) {
        this.article = article;
        this.fields.title = article.title;
        this.fields.slug = article.slug;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.article = {};
      this.fields.title = null;
      this.fields.slug = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {},
  },
};
</script>
