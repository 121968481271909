<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>News</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search News"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  color="accent"
                  v-on="on"
                  @click="$refs.articleDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Article</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-craigtoun-news-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed light v-on="on">
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Articles</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="articles"
        no-data-text="No Articles found"
      >
        <template v-slot:item.categories="{ item }">
          {{ item.categories.map((c) => c.name).join() }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                v-on="on"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Article</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                v-on="on"
                @click="$refs.articleDialog.open(item)"
                ><v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <article-dialog ref="articleDialog" />
  </div>
</template>

<script>
import ArticleDialog from "./components/ArticleDialog.vue";

export default {
  components: {
    ArticleDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "News",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Slug", value: "slug" },
        { text: "Categories", value: "categories" },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },

  computed: {
    articles() {
      let articles = this.$store.getters["craigtoun/newsStore/articles"];

      if (this.searchTerm !== "") {
        articles = articles.filter((a) => {
          const title = a.title.toLowerCase();
          const slug = a.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm) || slug.inclues(searchTerm);
        });
      }

      return articles;
    },
  },
};
</script>
